





























































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { ttt } from '@/nutils';

import {
  readUserProfile,
  readHasAdminAccess,
  readUserKPIBrand,
  readNewest3,
} from '@/store/main/getters';
import {
  dispatchGetUserKPIBrand,
  dispatchGetNewest3,
} from '@/store/main/actions';
import { IBrand } from '@/interfaces';

@Component
export default class Dashboard extends Vue {

  public ttt = ttt;

  public headers = [
    { text: ttt('Name'), sortable: false, value: 'shop_name', align: 'left', },
    { text: ttt('Description'), sortable: false, value: 'shop_description', align: 'left', },
    { text: ttt('Since'), sortable: false, value: 'registered_at', align: 'left', },
    { text: ttt('Given Referers'), sortable: false, value: 'analytics_displays', align: 'left', },
  ];

  get greetedUser() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.first_name && userProfile.last_name) {
        return `${userProfile.first_name} ${userProfile.last_name}`;
      } else {
        return userProfile.email;
      }
    }
  }

  get newestBrands() {
    const n3 = readNewest3(this.$store);
    // console.log(n3);
    return n3;
  }

  get myKPIs() {
    return readUserKPIBrand(this.$store);
  }

  public async mounted() {
    await dispatchGetUserKPIBrand(this.$store);
    await dispatchGetNewest3(this.$store);
  }
}
